import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import { Dialog, DialogContent, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import ToggleButton from '@mui/material/ToggleButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import getOrderFilterOptions from '../../../util/GetOrderFilterOptions';
import {
  setOrderDate,
  setOrderTime,
  checkTimeSet,
} from '../../../redux/legacy/reducers/ducks/legacy/OrderDuck';
import useClasses from '../../../hooks/legacy/useClasses';
import useDesktopCheck from '../../../util/hooks/UseDesktopCheck';
import CustomSwipeableDrawer from '../../layout/CustomSwipeableDrawer';
import { useWhen } from '../../../hooks/useWhen';
import { useRestaurant } from '../../../hooks/useRestaurant';

import styles from './EditTimeForm.style';

function EditTimeForm({
  open,
  onClose,
  anchorEl,
  reorderTimeForm,
  handleTimeSubmit,
  selectedRestaurantDetails,
}) {
  const classes = useClasses(styles);
  const dispatch = useDispatch();
  const isDesktop = useDesktopCheck();
  const { setSelectedTime, setSelectedDate } = useWhen();
  const { onSelectedRestaurantPage, selectedRestaurant } = useRestaurant();

  const { date, type, time, cartItems, reorderData } = useSelector(
    ({ order, time: selectedTime }) => ({
      date: selectedTime?.selectedDate,
      type: order?.type,
      time: selectedTime?.selectedTime,
      cartItems: order?.cartItems,
      reorderData: order?.reorder?.order,
    })
  );

  const [formDateValue, setFormDateValue] = useState(date);
  const [formTimeValue, setFormTimeValue] = useState(time);
  const [givenDateOptions, setDateOptions] = useState([]);
  const [givenTimeOptions, setTimeOptions] = useState([]);

  const dateTimeOptions = useMemo(() => {
    const todayDayNumber = new Date().getDate();
    const key =
      formDateValue?.fullName === 'Today' ? todayDayNumber : formDateValue?.key;
    return givenTimeOptions?.[key]?.[type.value] ?? [];
  }, [givenTimeOptions, formDateValue, type]);

  const resetState = useCallback(() => {
    setFormDateValue(date);
    setFormTimeValue(time);
  }, [date, time]);

  useEffect(() => {
    const { dateOptions, timeOptions } = getOrderFilterOptions(
      onSelectedRestaurantPage
        ? selectedRestaurant
        : selectedRestaurantDetails || null
    );
    setDateOptions(dateOptions);
    if (!formDateValue?.key) {
      setFormDateValue({
        ...dateOptions[0],
        key: dateOptions[0].dayOfMonth,
        value: dateOptions[0].value,
      });
    }
    setTimeOptions(timeOptions);
  }, [selectedRestaurant, onSelectedRestaurantPage, selectedRestaurantDetails]);

  useEffect(() => {
    if (formTimeValue?.label) {
      setSelectedTime(formTimeValue);
    }
  }, [formTimeValue]);

  useEffect(() => {
    if (time?.value === 'asap' && dateTimeOptions?.length) {
      setFormTimeValue(dateTimeOptions[0]);
    }
  }, [dateTimeOptions, time, date]);

  useEffect(() => {
    resetState();
  }, [date, time, resetState]);

  useEffect(() => {
    if (formDateValue) {
      const currentTimeLabel = formTimeValue?.label;

      let newInterval = null;
      if (currentTimeLabel) {
        const matchingIntervals = dateTimeOptions.filter(
          (interval) => interval.label === currentTimeLabel
        );
        newInterval = matchingIntervals[0] ?? null;
      }

      if (!newInterval) {
        newInterval = dateTimeOptions[0] ?? null;
      }

      if (!newInterval) {
        setFormTimeValue(dateTimeOptions[0] ?? null);
      } else {
        setFormTimeValue(newInterval);
      }
    }
  }, [formDateValue, dateTimeOptions]);

  const updateDate = (ev, value) => {
    if (value) {
      setFormDateValue(value);
    }
  };

  const updateTime = ({ target: { value } }) => {
    if (value) {
      setFormTimeValue(value);
    }
  };

  const handleSubmit = () => {
    dispatch(setOrderDate(formDateValue));
    // deprecated use useWhen hook instead
    dispatch(setOrderTime(formTimeValue));
    dispatch(checkTimeSet(true));

    // save time using useWhen hook
    setSelectedTime(formTimeValue);
    setSelectedDate(formDateValue);

    if (reorderTimeForm) {
      handleTimeSubmit(formDateValue, formTimeValue);
    } else {
      onClose();
    }
  };

  const stopPropagation = (ev) => ev.stopPropagation();

  const handleReset = () => {
    setFormDateValue(date);
    setFormTimeValue(time);
  };

  const EditTimeBasicForm = (
    <form onSubmit={handleSubmit} data-test-id="editTimeForm">
      <Stack spacing={2}>
        <ToggleButtonGroup
          value={formDateValue}
          color="secondary"
          exclusive
          fullWidth
          onChange={updateDate}
          data-test-id="toggleButton"
        >
          {givenDateOptions?.map((option) => (
            <ToggleButton
              data-test-id="dateOption"
              className={
                formDateValue && formDateValue.label === option.label
                  ? classes.selectDateColor
                  : classes.UnSelectDateColor
              }
              key={option.dayOfMonth}
              value={option}
            >
              <Stack spacing={0}>
                <Typography variant="caption">{option.label}</Typography>

                <Typography>{option.dayOfMonth}</Typography>
              </Stack>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        {dateTimeOptions.length > 0 ? (
          <Box className={classes.formRow}>
            <Select
              fullWidth
              variant="outlined"
              onChange={updateTime}
              value={formTimeValue}
              data-test-id="timeSelectWrapper"
              iconcomponent={ArrowDownwardIcon}
              classes={{
                root: classes.selectRoot,
                icon: classes.selectIcon,
              }}
              renderValue={() => (
                <Box className={classes.option}>{formTimeValue?.label}</Box>
              )}
              inputProps={{
                'data-test-id': 'timeSelect',
              }}
            >
              {dateTimeOptions?.map((option) => (
                <MenuItem
                  value={option}
                  data-test-id="timeOption"
                  key={`date-time-option-${
                    option.value?.start || option.value
                  }`}
                >
                  <Typography>{option?.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          <Box className="timeSelectionNotAvailable">
            <Typography align="center" variant="subtitle2">
              Sorry, not available for the selected day
            </Typography>
          </Box>
        )}

        {!time?.value && (
          <Typography>The selected time is no longer available</Typography>
        )}
        {(time?.value !== formTimeValue?.value ||
          date?.label !== formDateValue?.label) &&
        cartItems.length > 0 ? (
          <>
            <Typography>
              Attention: Changing the date or time may remove the items in your
              cart
            </Typography>
            <Box className={classes.buttons}>
              <Button
                className={classes.leftButton}
                variant="contained"
                fullWidth
                size="large"
                onClick={handleReset}
                data-test-id="resetButton"
              >
                Go back
              </Button>
              <Button
                variant="contained"
                fullWidth
                size="large"
                startIcon={<SaveIcon />}
                onClick={handleSubmit}
                data-test-id="updateButton"
              >
                Confirm Time Change
              </Button>
            </Box>
          </>
        ) : (
          <Box className={classes.buttons}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
              data-test-id="updateButton"
              disabled={!dateTimeOptions || dateTimeOptions.length === 0}
            >
              Update Order Time
            </Button>
          </Box>
        )}
      </Stack>
    </form>
  );

  const renderDesktop = () => (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      data-test-id="order-type-filter"
      onClick={stopPropagation}
    >
      <Box sx={{ width: 375, padding: 4 }}>
        <Typography mb={2} variant="h6">
          Order Time
        </Typography>
        <div data-test-id="editTimeForm">{EditTimeBasicForm}</div>
      </Box>
    </Popover>
  );

  const renderMobile = () => (
    <CustomSwipeableDrawer open={open} onClose={onClose} title="Order Time">
      <Box aria-hidden="true">{EditTimeBasicForm}</Box>
    </CustomSwipeableDrawer>
  );

  const reorderTimeFormDesktop = () => (
    <Dialog
      open={open}
      onClose={onClose}
      data-test-id="order-type-filter"
      fullWidth
      maxWidth="xs"
      sx={{ margin: 0 }}
    >
      <DialogContent
        className={classes.dialogWidth}
        sx={{ overflow: 'hidden' }}
      >
        <Box sx={{ width: 375, padding: 4 }}>
          <Typography mb={2} variant="h6">
            Order Time
          </Typography>
          <Typography variant="body1" mb={2}>
            {reorderData?.restaurant?.name} is not available for{' '}
            {reorderData?.type === 'delivery' ? 'Delivery' : 'Takeout'} at this
            time. Please select a new time to continue.
          </Typography>
          <Box data-test-id="editTimeForm">{EditTimeBasicForm}</Box>
        </Box>
      </DialogContent>
    </Dialog>
  );

  if (isDesktop) {
    if (!reorderTimeForm) {
      return renderDesktop();
    }
    return reorderTimeFormDesktop();
  }

  return renderMobile();
}

EditTimeForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  reorderTimeForm: PropTypes.bool,
  handleTimeSubmit: PropTypes.func,
  selectedRestaurantDetails: PropTypes.shape({}),
};

EditTimeForm.defaultProps = {
  open: false,
  onClose: () => null,
  reorderTimeForm: false,
  handleTimeSubmit: () => null,
};

export default EditTimeForm;
